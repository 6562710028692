import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { ErrorBoundary, Footer, Navbar } from './components/common';
import { EnvironmentProvider, PlaygroundProvider } from './contexts';

import './App.scss';

const Landing = lazy(() => import('./routes/landing/Landing'));
const PlaygroundPage = lazy(() => import('./routes/playground/PlaygroundPage'));
const ProjectPage = lazy(() => import('./routes/project/ProjectPage'));
const All = lazy(() => import('./routes/explore/All'));
const Mine = lazy(() => import('./routes/explore/Mine'));
const ComponentsCatalogue = lazy(() => import('./routes/components-catalogue/ComponentsCatalogue'));
const ComponentViewer = lazy(() => import('./routes/component-viewer/ComponentViewer'));

const Loading = () => (
  <BrowserRouter>
    <main className="bg-lvl4 vh-100 d-flex flex-column">
      <Navbar />
      <div style={{ minHeight: 'calc(100vh - 99px)' }}>
        <div className="container">
          <div className="blinker blinker-xl display-6">Loading</div>
        </div>
      </div>
      <Footer />
    </main>
  </BrowserRouter>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />}>
      <Route index element={<Landing />} />
      <Route path="create" element={<PlaygroundPage />} />
      <Route path="editor/:playgroundId" element={<PlaygroundPage />} />
      <Route path="project/:projectId" element={<ProjectPage />} />
      <Route path="mine" element={<Navigate to="/mine/playgrounds" replace />} />
      <Route path="mine/projects" element={<Mine />} />
      <Route path="mine/playgrounds" element={<Mine />} />
      <Route path="all" element={<Navigate to="/all/playgrounds" replace />} />
      <Route path="all/projects" element={<All />} />
      <Route path="all/playgrounds" element={<All />} />
      <Route path="components" element={<ComponentsCatalogue />} />
      <Route path="component-viewer/:componentKey" element={<ComponentViewer />} />
    </Route>
  )
);

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <EnvironmentProvider>
      <PlaygroundProvider>
        <Helmet title="SGM Design Lab" />
        <Suspense fallback={<Loading />}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </Suspense>
      </PlaygroundProvider>
    </EnvironmentProvider>
  );
};
